import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import querystring from 'query-string'
import { globalHistory } from '@reach/router'

import Card from './Card'
import CustomerForm from './CustomerForm'
import SuccessCard from './SuccessCard'
import useFetch from '../../hooks/useFetch'
import { toast } from 'react-toastify'

import Loader from '../Loader'
import './styles.scss'


const showError = () => {
  toast && toast.error('Sorry, Something went wrong.:( Please try again.',
    {
      position: 'top-center',
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    }
  )
}

const GenericLoyaltyIVR = props => {
  const { search: searchParameter } = globalHistory.location
  const queryParameterObj = querystring.parse(searchParameter)
  const { p: phone = '', name = '', c: chainUuid, s: storeUuid } = queryParameterObj
  const [successCard, toggleSuccessCard] = useState(false)
  const [ ivrData, setIvrData ] = useState({})
  const [ requestState, callApi ] = useFetch()
  const { loading } = requestState

  useEffect(() => {
    const url = `${process.env.GATSBY_INTEGRATION_URL}/restaurants/${storeUuid}/ivr`
    callApi(url)
  }, [callApi, storeUuid])

  useEffect(() => {
    const { response, error } = requestState
    if(response) {
      setIvrData( response )
    }
    if(error){
      showError()
    }
  }, [setIvrData, requestState])

  if(loading) {
    return <Loader className='eg-loyalty-loader' />
  }

  if(!ivrData.discount_amount){
    return ''
  }

  const renderForm = () => {
    return (
      <>
        <div className='title'>
          {ivrData.headline}
        </div>

        <div className='subtitle'>
          {ivrData.subtext}
        </div>

        <div className='card'>
          <Card title={`$${ivrData.discount_amount} off`} name={name} phone={phone} />
        </div>

        <div className='customer-info'>
          <CustomerForm 
            toggleSuccessCard={toggleSuccessCard}
            data={
              { 
                phone,
                chainUuid,
                storeUuid
              }
            }
          />
        </div>

        <div className='service-info'>
          <p>
            **the {ivrData.discount_amount} dollar off discount will be deducted on your next purchase with
            <span> {ivrData.restaurant}</span>.
          </p>
          <p>
            By joining this rewards program I give my express written consent to receive marketing
            text or native mobile messages via automated technology (e.g., automatic telephone
            dialing systems) from <span>{ ivrData.restaurant }</span> at the mobile number provided. Up to eight
            messages per month. Consent to receive messages is not a condition of purchasing any property,
            goods or services. Text STOP to end to 244547. MSG & data rates may apply.
            I also agree to the Privacy Policy & Terms & Conditions.
          </p>
        </div>
      </>
    )
  }

  return (
    <div className='eg-loyalty-ivr-container'>
      <div className='eg-loyalty-ivr'>
        {
          !!ivrData.ivr_logo && <img className='fp-logo' src={ivrData.ivr_logo} alt='store'/>
        }
        {successCard
          ? <SuccessCard data={ ivrData } />
          : renderForm()
        }     
      </div>
    </div>
  )
}

GenericLoyaltyIVR.propTypes = {
  source: PropTypes.string
}

export default GenericLoyaltyIVR
