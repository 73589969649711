import React from 'react'
import PropTypes from 'prop-types'

import './styles.scss'

/**
 * A component to render successful form submission information.
 * @param {*} props 
 */
const SuccessCard = props => {
  const { data } = props
  return (
    <div className='success-card'>
      <div className='logo' />
      <div className='title'>
        <div>Well done!</div>
        Your <span>{ data.restaurant }</span> rewards profile is now active
      </div>
      <div className='info'>
        {`The $${data.discount_amount} discount will be applied on your next purchase with `}
        <span>{ data.restaurant }</span>.
      </div>
    </div>
  )
}

SuccessCard.propTypes = {
  data: PropTypes.object
}

export default SuccessCard
